import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyAewMfjDCp4rS-tKGrgZWrVMgBiASqCj1E",
  authDomain: "comingsoon-f0c8a.firebaseapp.com",
  projectId: "comingsoon-f0c8a",
  storageBucket: "comingsoon-f0c8a.appspot.com",
  messagingSenderId: "268669369091",
  appId: "1:268669369091:web:9651c813d01f45d9278736"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };