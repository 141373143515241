// src/App.js
import React, { useState , useEffect } from 'react';
import { db } from './firebase'; // Import Firestore database instance
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import { getFunctions, httpsCallable } from 'firebase/functions';

const App = () => {
  console.log('App component rendered');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Load the reCAPTCHA v3 script
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Execute reCAPTCHA with action
      window.grecaptcha.ready(async() => {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(async (token) => {

          const functions = getFunctions();
      const verifyRecaptchaAndRegister = httpsCallable(functions, 'verifyRecaptchaAndRegister');
      const result = await verifyRecaptchaAndRegister({ name, email, recaptchaToken: token })

          // Add a new document to Firestore
          await addDoc(collection(db, 'interests'), {
            name,
            email,
            recaptchaToken: token, // You should verify this token on your server
          });
          setSubmitted(true); // If the data is successfully added
        });
      });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white">
      <header className="mb-8">
        <img src="logo.png" alt="Logo" className="w-[300] h-auto" />
      </header>
      <div className="relative p-8 bg-white bg-opacity-10 backdrop-blur-lg rounded-lg shadow-xl w-96">
        {submitted ? (
          <h2 className="text-center text-2xl font-bold text-white">
            Thank you for registering your interest!
          </h2>
        ) : (
          <>
            <h2 className="text-center text-2xl font-bold text-white">
              Register your interest
            </h2><br></br>
            <p className="text-center text-gray-200 mb-4">
              We're currently in the final stages of developing the SafetyAssistance app. 
            </p>
            <p className="text-center text-gray-200 mb-4">Register your email for regular updates and introductory offers.</p>
            <form onSubmit={handleSubmit}>
              <input
                className="w-full p-3 mb-4 bg-white bg-opacity-30 rounded-lg text-white"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                className="w-full p-3 mb-4 bg-white bg-opacity-30 rounded-lg text-white"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button
                className="w-full bg-gradient-to-r from-blue-500 to-orange-500 p-3 rounded-lg text-white hover:opacity-90 transition-opacity"
                type="submit"
              >
                Register Interest
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  
  );
  
};

export default App;
